import ForgeUnits from './forgeUnitsService';
import UnitEnum from './shared/UnitConversion/UnitEnums';
import * as consts from './consts';
import i18n from './i18n';
import { re } from 'mathjs';

export function GJtoMWh(value) {
  return ForgeUnits.convertGJtoMWh(value);
}

export function MJm2tokWhm2(value) {
  return ForgeUnits.convertMJm2tokWhm2(value);
}

export function kWhm2toMJm2(value) {
  return ForgeUnits.convertkWhm2toMJm2(value);
}

export function GJtokBtu(value) {
  return ForgeUnits.convertGJtokBtu(value);
}

export function Wm2toWft2(value) {
  return ForgeUnits.convertWm2toWft2(value);
}

export function CFMm2toCFMft2(value) {
  return ForgeUnits.convertCFMm2toCFMft2(value);
}

export function MJm2tokBtuft2(value) {
  return ForgeUnits.convertMJm2tokBtuft2(value);
}

export function Wft2toWm2(value) {
  return ForgeUnits.convertWft2toWm2(value);
}

export function CFMft2toCFMm2(value) {
  return ForgeUnits.convertCFMft2toCFMm2(value);
}

export function GJtokWh(value) {
  return ForgeUnits.convertGJtoKWh(value);
}

export const KgToTons = (value: number): number => {
  return ForgeUnits.convertKgToTons(value);
};

export const KgToPounds = (value: number): number => {
  return ForgeUnits.convertKgtoPounds(value);
};

export function UsdPerM2toUsdPerFT2(value) {
  return ForgeUnits.convertUsdM2toUsdFT2(value);
}

export function ThermstoKwh(value) {
  return ForgeUnits.convertThermstoKwh(value);
}

export function m3hm2toCFMft2(value) {
  return ForgeUnits.convertm3hm2toCFMft2(value);
}

export function CFMft2tom3hm2(value) {
  return ForgeUnits.convertCFMft2tom3hm2(value);
}

export function MetersToFeets(value) {
  return ForgeUnits.convertMetersToFeets(value);
}

export function M2ToFt2(value) {
  return ForgeUnits.convertm2toft2(value);
}

export function M3ToFt3(value) {
  return ForgeUnits.convertm3toft3(value);
}

export function Kgm3toPoundsFt3(value) {
  return ForgeUnits.convertKgm3toPoundsFt3(value);
}

export function Kgm2toPoundsFt2(value) {
  return ForgeUnits.convertKGm2toPoundsFt2(value);
}

export function MetersToMillimeters(value) {
  return ForgeUnits.convertmtomm(value);
}

export function MeterstoInches(value) {
  return ForgeUnits.convertmtoin(value);
}

export function M2KWTohrFt2Btu(value) {
  return ForgeUnits.convertM2KWTohrFt2Btu(value);
}

export function KiloWToMegaW(value) {
  return ForgeUnits.convertKiloWToMegaW(value);
}

export function MegaJToGigaJ(value) {
  return ForgeUnits.convertMegaJToGiga(value);
}

export function KbtuToKwh(value) {
  return ForgeUnits.convertKBtuToKwh(value);
}

export function KbtuToTherms(value) {
  return ForgeUnits.convertKBtuToTherms(value);
}

export function KwhToTherms(value) {
  return ForgeUnits.convertKwhToTherms(value);
}

export function convertUnknownToKwh(value: number, sourceEnergyUnitSchema: string) {
  try {
    return ForgeUnits.convertUnknownToKwh(value, sourceEnergyUnitSchema);
  } catch (error) {
    return value;
  }
}

export function CovertToBaseCurrency(rate, value) {
  return round(value * rate, 4);
}

export function CovertFromBaseCurrency(rate, value) {
  return round(value / rate, 4);
}

export function round(value, decimals?) {
  return ForgeUnits.roundValue(value, decimals);
}

export function getFractionValue(value) {
  return ForgeUnits.convertToFractionValue(value);
}

export function GetSymbol(unit: UnitEnum) {
  let unitSchema: string = '';
  switch (unit) {
    case UnitEnum.Millimeter:
      unitSchema = consts.SYMBOL_MM;
      break;
    case UnitEnum.Inch:
      unitSchema = consts.SYMBOL_IN;
      break;
    case UnitEnum.KgPerM3:
      unitSchema = consts.SYMBOL_KGPERMSUP3;
      break;
    case UnitEnum.PoundsPerFt3:
      unitSchema = consts.SYMBOL_POUNDSPERFTSUP3;
      break;
    case UnitEnum.M2KPerW:
      unitSchema = consts.SYMBOL_MSSUP2KPERW;
      break;
    case UnitEnum.HrFt2PerBtu:
      unitSchema = consts.SYMBOL_HOURFTSUP2DEGREEFPERBTU;
      break;
    case UnitEnum.MeterSq:
      unitSchema = consts.SYMBOL_MSUP2;
      break;
    case UnitEnum.FeetSq:
      unitSchema = consts.SYMBOL_FTSUP2;
      break;
    case UnitEnum.Meter3:
      unitSchema = consts.SYMBOL_MSUP3;
      break;
    case UnitEnum.Feet3:
      unitSchema = consts.SYMBOL_FTSUP3;
      break;
    case UnitEnum.Kg:
      unitSchema = consts.SYMBOL_KG;
      break;
    case UnitEnum.Pounds:
      unitSchema = consts.SYMBOL_LB;
      break;
    case UnitEnum.KgCO2e:
      return i18n.t('symbol.unit.KgCO2e');
    case UnitEnum.KgCO2ePerM2:
      return i18n.t('symbol.unit.KgCO2ePerM2');
    case UnitEnum.KgCO2ePerFt2:
      return i18n.t('symbol.unit.KgCO2ePerFt2');
    case UnitEnum.Mbtu:
      return i18n.t('symbol.unit.Mbtu');
    case UnitEnum.WPerM2K:
      unitSchema = consts.SYMBOL_WPERSQMK;
      break;
    case UnitEnum.BtuPerHrFt2F:
      unitSchema = consts.SYMBOL_BTUPERHOURSQFOOTDEGF;
      break;
    default:
      unitSchema = '';
  }
  return ForgeUnits.getSymbol(unitSchema);
}
