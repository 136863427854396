import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import FormLabel, { formLabelVariants } from '@weave-mui/form-label';
import Box from '@weave-mui/box';
import { FormControl } from '@weave-mui/form-control';
import { RadioGroup } from '@weave-mui/material';
import Dropdown from '@weave-design/dropdown';
import { unitsDropdownStylesheet } from '../../../styles/metrics.stylesheet';
import {AdvancedFactor, DataPointType, Factor, SimulationFactor} from '../../../../types/metrics';
import { isAdvancedFactor } from '../../../utils/factorsUtils';
import i18n from '../../../../i18n';
import { newDataPointId } from '../../../utils/dataPointsUtils';
import DataPointsIcon from '../../../../shared/DataPointsIcons/components/DataPointsIcon';
import DataPointsIconsEnum from '../../../../shared/DataPointsIcons/utils/iconEnum';
import Typography from '@weave-mui/typography';
import { AlertS, InfoS } from '@weave-mui/icons-weave';
import { InfoTooltipIcon } from '../../../../shared/InfoTooltipIcon';
import FormControlTooltipLabel from './FormControlTooltipLabel';

interface SimulationControlProps {
  selectedFactor: Factor | AdvancedFactor;
  simulationFactors: SimulationFactor[];
  isReadOnly: boolean;
  setSelectedFactor: Dispatch<SetStateAction<Factor | AdvancedFactor>>;
  usedParametersInAdvancedFactors: string[];
  toggleSimulationCheckbox: (value: boolean) => void;
}

export enum FactorTypes {
  SIMULATION = 'simulation',
  BASIC = 'basic',
}

export type FactorType = FactorTypes.SIMULATION | FactorTypes.BASIC;

const generateFactorType = (factor: Factor | SimulationFactor): FactorType =>
  isAdvancedFactor(factor)
    ? FactorTypes.SIMULATION
    : FactorTypes.BASIC;

const SimulationControl: React.FC<SimulationControlProps> = ({simulationFactors, isReadOnly, selectedFactor, setSelectedFactor, usedParametersInAdvancedFactors, toggleSimulationCheckbox }) => {
  const [factorType, setFactorType] = useState<FactorType>(selectedFactor && generateFactorType(selectedFactor));

  const availableSimulationFactors = useMemo(() => {
    return simulationFactors.filter((sf => !usedParametersInAdvancedFactors.includes(sf.id)));
  }, [simulationFactors, usedParametersInAdvancedFactors]);

  const maximumAdvancedFactorsCreated = useMemo(() => availableSimulationFactors.length === 0, [availableSimulationFactors]);

  useEffect(() => {
    setFactorType(generateFactorType(selectedFactor))
  }, [selectedFactor.id]);

  const toggleFactorType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value: FactorType = event.target.value as unknown as FactorType;
    setFactorType(value);
    toggleSimulationCheckbox(value === FactorTypes.SIMULATION)
    if (value === FactorTypes.BASIC) {
      setSelectedFactor(prevFactor => {
        const updatedFactor = {
          ...prevFactor,
          type: DataPointType.Factor,
          unit: '',
          dataType: '',
          dataPointValue: {
            values: [
              {
                name: i18n.t('analysis.dataPoints.factors.defaultValueName'),
                value: 0,
              }
            ]
          }
        } as AdvancedFactor;

        if (updatedFactor.simulationFactor) {
          delete updatedFactor.simulationFactor;
        }

        return updatedFactor;
      })
    }
  }, []);

  const onParameterChange = useCallback((option) => {
    const selectedOOTBFactorId = option as string;
    setSelectedFactor(prevFactor => {
      return {
        ...prevFactor,
        simulationFactor: selectedOOTBFactorId,
        type: DataPointType.AdvancedFactor,
        dataPointValue: {
          values: simulationFactors.find(sF => sF.id === selectedOOTBFactorId)?.dataPointValue?.values ?? []
        }
      }
    });
  }, []);

  const getParameterNameById = useCallback((id: string): string => {
    return simulationFactors.find(p => p.id === id)?.displayName ?? '';
  },[simulationFactors]);

  const generateRadioLabels = useCallback((isSimulationRadio = false): JSX.Element => {
    return (
      <Box
        sx={
          {
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }
        }
      >
        <DataPointsIcon icon={
          isSimulationRadio
            ? DataPointsIconsEnum.AdvancedFactors
            : DataPointsIconsEnum.BasicFactors
          }
        />
        <Typography>
          {
            isSimulationRadio
              ? i18n.t('analysis.dataPoints.factors.simulation.radioButton.simulationFactor')
              : i18n.t('analysis.dataPoints.factors.simulation.radioButton.nonSimulationFactor')
          }
        </Typography>
      </Box>
    )
  }, []);

  return (
    <Box>
      <FormControl
        sx={
          {
            display: 'flex',
            flexDirection: 'row',
            pt: '1rem',
            width: '100%',
            gap: '0.3rem'
          }
        }
      >
        <FormLabel
          sx={
            {
              alignSelf: 'flex-start',
              p: 0,
              pt: '0.4rem',
              width: '100px',
              minWidth: '100px',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }
          }
          variant={formLabelVariants.SIDE}
        >
          {i18n.t('analysis.dataPoints.factors.simulation.label.radioControl')}
          <InfoTooltipIcon
            icon={<InfoS color="action"/>}
            tooltiPlacement={'right'}
            tooltipTitle={i18n.t('analysis.dataPoints.factors.simulation.label.tooltipTitle')}
            tooltipContent={i18n.t('analysis.dataPoints.factors.simulation.label.tooltipDescription')}
          />
        </FormLabel>
        <Box  sx={
          {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
          }
        }>
          <Box sx={
            {
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              width: 'max-content'
            }
          }>
            <RadioGroup
              aria-labelledby='factors-radio-buttons-group-label'
              name='factors-radio-buttons-group'
              data-testid='factors-radio-buttons-group'
              defaultValue={false}
              value={factorType}
              onChange={toggleFactorType}
              sx={{
                '& .Mui-disabled': {
                  opacity: '0.8 !important',
                },
                '& .MuiFormControlLabel-label.Mui-disabled':{
                  color: 'rgba(0,0,0,1) !important',
              }}}
            >
              <FormControlTooltipLabel
                isRadioDisabled={isReadOnly || selectedFactor.id !== newDataPointId}
                radioLabel={generateRadioLabels()}
                value={FactorTypes.BASIC}
                tooltipTitle={i18n.t('analysis.dataPoints.factors.simulation.radioButton.nonSimulationFactor')}
                tooltipDescription={i18n.t('analysis.dataPoints.factors.simulation.radioButton.nonSimulationDescription')}
                testId='factors-radio-button-nonSimulationFactor'
              />
              <FormControlTooltipLabel
                isRadioDisabled={isReadOnly || selectedFactor.id !== newDataPointId || maximumAdvancedFactorsCreated}
                radioLabel={generateRadioLabels(true)}
                value={FactorTypes.SIMULATION}
                tooltipTitle={i18n.t('analysis.dataPoints.factors.simulation.radioButton.simulationFactor')}
                tooltipDescription={i18n.t('analysis.dataPoints.factors.simulation.radioButton.simulationFactorDescription')}
                testId='factors-radio-button-simulationFactor'
              />
            </RadioGroup>
            { (maximumAdvancedFactorsCreated && selectedFactor?.id === newDataPointId) &&
              (
                <Box sx={{
                  display: 'flex',
                  pl: '0.25rem',
                  pt: '0.5rem',
                  gap: '0.5rem'
                }}>
                  <AlertS sx={{ fill: '#FAA21B !important' }} />
                  <Typography>
                    {i18n.t('analysis.dataPoints.factors.simulation.radioButton.maximumNumbersCreatedDescription')}
                  </Typography>
                </Box>
              )
            }
          </Box>
          {factorType === FactorTypes.SIMULATION && (
            <Box sx={
              {
                width: 'max-content',
                pl: '0.5rem'
              }
            }>
                <Box>
                  <Dropdown
                    stylesheet={unitsDropdownStylesheet(isReadOnly)}
                    onChange={onParameterChange}
                    data-testid='factors-parameter-dropdown'
                    options={
                      availableSimulationFactors.map(p => p.id)
                    }
                    formatOption={(id: string) => getParameterNameById(id)}
                    value={(selectedFactor as AdvancedFactor).simulationFactor || ''}
                    placeholder={i18n.t('analysis.dataPoints.factors.simulation.placeholder.dropdown')}
                    disabled={selectedFactor.id !== newDataPointId}
                    typable={true}
                  />
                </Box>
            </Box>
          )}
        </Box>
      </FormControl>
    </Box>
  )
}

export default SimulationControl;
